import { AuthTokenService } from './../../services/auth-token.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-core-navbar',
  templateUrl: './core-navbar.component.html',
  styleUrls: ['./core-navbar.component.scss'],
})
export class CoreNavbarComponent implements OnInit {
  @Output() eventEmitter = new EventEmitter();

  @Input() toggle: boolean;

  showDropdown = false;
  activeDropdown = false;
  name: string;

  constructor(private authTokenService: AuthTokenService) {}

  ngOnInit(): void {
    const session = this.authTokenService.decodePayloadJWT();
    this.name = session?.given_name;
  }

  toggleSidebar(): void {
    this.eventEmitter.emit('toggleSidebar');
  }

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
    this.activeDropdown = !this.activeDropdown;
  }
}
