<div class="sidebar" [ngStyle]="showSidebar ? {'margin-left': '0'} : {'margin-left': '-500px'}">
  <div class="sidebar-header">
  </div>
  <div class="sidebar__body">
    <div class="sidebar__body-menu-item">
      <div class="item-head">
        <span class="item-head__title">Telemarketing</span>
      </div>
      <div class="item-options">
        <ul class="item-options__list">
          <li *ngIf="isAdmin" [routerLink]="['/telemarketing/listas']" routerLinkActive="router-link-active" (click)="closeSidebar()">
            Listas
          </li>
          <li [routerLink]="['/telemarketing/contatos']" routerLinkActive="router-link-active" (click)="closeSidebar()">
            Contatos
          </li>          
        </ul>
      </div>
    </div>


</div>
