<div class="core-navbar">
  <div class="menu-button" (click)="toggleSidebar()">
    <button class="menu-button__button">
      <img class="menu-button__icon" src="assets/icon/burger-menu.svg" alt="menu-icon">
    </button>
  </div>
  <div class="navbar">
    <div class="navbar-title">
      <h3 class="navbar-title__title" [ngClass]="toggle ? 'navbar-title__title-show' : 'navbar-title__title-hide'">
        Portal Loja
      </h3>
    </div>
    <div class="navbar-logo">
      <img class="navbar-logo__image" src="assets/image/logo-smaller.svg" alt="logo">
    </div>
    <div class="navbar-notifications">

    </div>
  </div>
  <div class="login-section" (click)="toggleDropdown()">
    <app-user-dropdown [showDropdown]="showDropdown" [name]="name"></app-user-dropdown>
  </div>
</div>
