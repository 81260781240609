import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnlyAuthGuard } from './shared/guards/only-auth.guard';
import { NotFoundModule } from './not-found/not-found.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'telemarketing',
    loadChildren: () => import('./telemarketing/telemarketing.module').then(m => m.TelemarketingModule),
    canActivate: [OnlyAuthGuard]
  },
  {
    path: 'not-found',
    loadChildren: () => NotFoundModule
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
