import { AuthTokenService } from './auth-token.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(private authToeknService: AuthTokenService) {}

  public checkPermission(permission: string): boolean {
    const session = this.authToeknService.decodePayloadJWT();
    const roles = session?.resource_access?.['portal-varejo']?.roles;

    if (roles) {
      return roles.includes(permission);
    }

    return false;
  }
}
