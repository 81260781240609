import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: string) {
    if (value) {
      let formatedValue: string;

      switch (value.length) {
        case 12:
          // Fax
          formatedValue = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/g, '\+$1 \$2 \$3 \$4');
          break;

        case 11:
          // Cell phone
          formatedValue = value.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, '\($1) \$2\$3-\$4');
          break;

        case 10:
          // Phone
          formatedValue = value.replace(/(\d{2})(\d{4})(\d{4})/g, '\($1) \$2-\$3');
          break;

        case 8:
          // Phone
          formatedValue = value.replace(/(\d{4})(\d{4})/g, '\$1-\$2');
          break;

        default:
          formatedValue = value;
          break;
      }

      return formatedValue;
    } else {
      return value;
    }
  }

}
