import { AuthService } from './../../../auth/services/auth.service';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  loggedIn: boolean;
  showSidebar: boolean;
  isLogin: boolean;
  isAuthenticated$: Observable<boolean>;

  constructor(private router: Router, private authService: AuthService) {
    this.isAuthenticated$ = this.authService.isAuthenticated$;
  }

  ngOnInit() {
    this.router.events.subscribe((routeEvent) => {
      if (routeEvent instanceof NavigationEnd) {
        const pathName = window.location.pathname;
        this.isLogin = pathName.length > 2;
      }
    });

    if (this.isAuthenticated$) {
      this.router.navigate(['telemarketing', 'contatos']);
    }
  }

  toggleSidebar(): void {
    this.showSidebar = !this.showSidebar;
  }

  hideMenus(): void {
    // this.dataService.setHider(false);
    this.showSidebar = false;
  }
}
