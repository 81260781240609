<div class="not-found">
  <div class="not-found__page-content">
    <h1><b>Página não encontrada</b></h1>
    <span class="not-found__page-content--text">
      A página que você está procurando não foi encontrada, utilize o menu para encontrar a <br />
      página desejada ou retorne para a Home.
    </span>
    <div class="not-found--button">
      <button class="btn -large voltar" (click)="returnToDash()">Home</button>
    </div>
  </div>
</div>
