import { RouterModule } from '@angular/router';
import { UserDropdownComponent } from './components/user-dropdown/user-dropdown.component';
import { CoreNavbarComponent } from './components/core-navbar/core-navbar.component';
import { CoreSidebarComponent } from './components/core-sidebar/core-sidebar.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AngularMaterialModule } from './angular-material.module';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HttpClientModule } from '@angular/common/http';
import { PipesModule } from './pipes/pipes.module';
import { NotFoundModule } from '../not-found/not-found.module';


@NgModule({
  declarations: [
    MainLayoutComponent,
    LoadingComponent,
    CoreSidebarComponent,
    CoreNavbarComponent,
    UserDropdownComponent,
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    AngularMaterialModule,
    RouterModule,
    PipesModule,
    HttpClientModule,
  ],
  exports: [
    MainLayoutComponent,
    LoadingComponent,
    CoreSidebarComponent,
    CoreNavbarComponent,
    UserDropdownComponent,
    PipesModule,
    NotFoundModule
  ],
  providers: []
})
export class SharedModule {}
