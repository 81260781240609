<div class="login-area" [ngClass]="{'active-dropdown': showDropdown}">
  <div class="user-avatar">
    <!-- <img class="user-avatar__image" src="assets/image/dummy-user.png" alt="avatar"> -->
    <img class="user-avatar__image" src="assets/icon/menu-icon.svg" alt="Menu Icon">
  </div>
  <div class="user-name">
    <span class="user-name__name" *ngIf="name">Olá, <strong>{{ name }}!</strong></span>
  </div>
  <!-- <div class="user-arrow">
        <img src="assets/icon/navbar-single-down-arrow.svg" alt="arrow">
    </div> -->
</div>

<div class="dropdown" (click)="cancelToggle($event)"
  [ngStyle]="showDropdown ? {'right' : '0', 'display': 'block'} : { 'right': '-1000px' , 'display': 'none'}">
  <ul class="dropdown__menu">
    <li class="dropdown__menu__item" [ngClass]="{'last-button': !isBackoffice}">
      <button (click)="logout()">Logout</button>
    </li>
  </ul>
</div>
