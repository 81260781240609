import { ToastrService } from 'ngx-toastr';
import { ISession } from './../../shared/data/interfaces/session/session.interface';
import {
  HttpHeaders,
  HttpParams,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from 'src/app/shared/services/session.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthTokenService } from 'src/app/shared/services/auth-token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuthenticated$ = this.sessionService.session$.pipe(
    map((session) => this.isAuthenticated(session))
  );

  private refreshTokenTimeout?: any;

  constructor(
    private httpClientService: HttpClient,
    private sessionService: SessionService,
    private router: Router,
    private toastService: ToastrService,
    private AuthTokenService: AuthTokenService
  ) {}

  isAuthenticated(session = this.sessionService.getSession()) {
    return !!session;
  }

  auth(login: string, senha: string) {
    const options = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      ),
    };

    const params = new HttpParams({
      fromObject: {
        grant_type: 'password',
        username: login,
        password: senha,
      },
    });

    return this.httpClientService
      .post(
        `${environment.api.base}/protocol/openid-connect/token`,
        params.toString(),
        options
      )
      .subscribe(
        (response: ISession) => {
          const secondParams = new HttpParams({
            fromObject: {
              grant_type: 'urn:ietf:params:oauth:grant-type:uma-ticket',
              audience: 'portal-varejo',
            },
          });

          const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', `Bearer ${response.access_token}`);

          return this.httpClientService
            .post(
              `${environment.api.base}/protocol/openid-connect/token`,
              secondParams.toString(),
              { headers }
            )
            .subscribe(
              (res: ISession) => {
                this.sessionService.setSession(res);
                this.startRefreshTokenTimer();
                this.router
                  .navigateByUrl('/telemarketing')
              },
              (error: HttpErrorResponse) => {
                
                if(error.status == 403){
                  this.toastService.error(
                    'Usuário sem acesso ao sistema.'
                  );
                  return;
                }
                
                this.toastService.error(
                  'Houve um erro ao obter permissões. Tente fazer o login novamente.'
                );
              }
            );
        },
        (error: HttpErrorResponse) => {
          if (error.error.error_description === 'Invalid user credentials') {
            this.toastService.error(
              'Senha e/ou nome de usuário incorretos. Tente novamente.'
            );
          } else {
            this.toastService.error(
              'Houve um erro ao fazer o login. Tente novamente.'
            );
          }
        }
      );
  }

  logout(): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', `Bearer ${this.sessionService.getSession().access_token}`);
      const params = new HttpParams({
        fromObject: {
          refresh_token: this.sessionService.getSession().refresh_token
        },
      });


    return this.httpClientService.post(
        `${environment.api.base}/protocol/openid-connect/logout`,
        params.toString(),
        { headers }
      )
  }

  refreshAPIToken(refresh_token: string): Observable<ISession> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      const params = new HttpParams({
        fromObject: {
          'grant_type': 'refresh_token',
          audience: 'portal-varejo',
          refresh_token: refresh_token,
        },
      });


    return this.httpClientService.post<ISession>(
        `${environment.api.base}/protocol/openid-connect/token`,
        params.toString(),
        { headers }
      )
  }
  
  signout(): void {
    this.stopRefreshTokenTimer();
    this.sessionService.resetSession().then(() => {
      this.router.navigate(['/']).then(() => location.reload());
    })
  
  }

  
  // helpers

  private startRefreshTokenTimer(): void {
    const token = this.sessionService.getSession();

    if (token) {
      const oneMinuteInMilliseconds = (60 * 1000);
      this.refreshTokenTimeout = setTimeout(
        () => this.refreshToken().subscribe({
          // caso de erro no refresh do token faz o logout do usuário
          error: (error) => {
            if(error instanceof HttpErrorResponse && [400, 401].includes(error.status)) {
              this.signout();
            }
          },
        }),
        (token.expires_in * 1000) - oneMinuteInMilliseconds
        
      );
    }
  }

  refreshToken(): Observable<ISession> {
    const token = this.sessionService.getSession();
    if (token) {
    
      return this.refreshAPIToken(token.refresh_token).pipe(
        tap(token => {
          this.startRefreshTokenTimer();
          this.sessionService.setSession(token);
        })
      )
    } else {
      return throwError(new Error('Não foi possivel renovar o token'));
    }
  }

  private stopRefreshTokenTimer(): void {
    clearTimeout(this.refreshTokenTimeout);
  }
}