import { RolesService } from './../../services/roles.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-core-sidebar',
  templateUrl: './core-sidebar.component.html',
  styleUrls: ['./core-sidebar.component.scss'],
})
export class CoreSidebarComponent implements OnInit {
  isSeller: boolean;
  isBackOffice: boolean;

  statusSeller: string;

  isAdmin = false;

  @Input() showSidebar: boolean;
  @Output() emitEvent = new EventEmitter();

  constructor(private rolesService: RolesService) {}

  ngOnInit(): void {
    this.isAdmin = this.rolesService.checkPermission('telemarketing-admin');
  }

  closeSidebar(): void {
    this.emitEvent.emit('toggleSidebar');
  }
}
