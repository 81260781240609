<!-- Sidebar -->
<app-core-sidebar *ngIf="isAuthenticated$ | async" [showSidebar]="showSidebar" (emitEvent)="toggleSidebar()">
</app-core-sidebar>

<!-- Navbar and content -->
<div class="navbar-content">
  <!-- Navbar -->
  <app-core-navbar *ngIf="isAuthenticated$ | async" [toggle]="showSidebar" (eventEmitter)="toggleSidebar()">
  </app-core-navbar>

  <!-- Content -->
  <div class="navbar-content__content" (click)="hideMenus()">
    <!-- Notifications -->
    <!-- <app-core-notifications></app-core-notifications> -->

    <ng-content select="[body]">
    </ng-content>

  </div>
</div>
