import { AuthService } from './../../../auth/services/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss']
})
export class UserDropdownComponent implements OnInit {

  @Input() showDropdown: boolean;
  @Input() name: string;
  isBackoffice = true;

  constructor(
    public authService: AuthService,
    private sessionService: SessionService,
    private router: Router
  ) { }

  ngOnInit(): void {

  }

  cancelToggle(e: Event): void {
    e.stopPropagation();
  }

  logout(): void {
    this.authService.logout().subscribe(
      r => {
        this.sessionService.resetSession().then(() => {
          this.router.navigate(['/']).then(() => location.reload());
        })
      },
      error => {
        console.error("Erro ao realizar logout:", error);
        
        // Mesmo se der erro, limpa a session e envia para o login para um novo login e token.
        this.sessionService.resetSession().then(() => {
          this.router.navigate(['/']).then(() => location.reload());
        })
      }
    );
    
  }  
}
